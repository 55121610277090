<template>
    <div>
        <b-modal class="rounded-lg" size="md" v-model="modalOperaciones" :title="title" no-close-on-esc no-close-on-backdrop hide-header-close hide-footer centered>
            <div class="d-flex justify-content-center">
                <b-col cols="12" sm="12" md="12" lg="12" class="mt-2">
                        <div class="d-flex">
                            <i class="icon_color_main fa fa-stethoscope fa-lg  my-auto mr-2"></i>
                            <b-form-input v-model="dataOp.nombre" name="name" autocomplete="off" class="form_input-color border-0 mt-1 psw" size="md" type="text" placeholder="Ingresa el nombre de la operación" />
                        </div>
                        <div>
                            <span class="msjError ml-4">{{msjError}}</span> 
                        </div>
                </b-col>
            </div>
            <div class="d-flex justify-content-end mt-3 pr-3">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                >
                    <button v-if="save" @click="guardar()" :disabled="busy" class="btn-modal-success border-0 px-3 pb-2 pt-1">Guardar</button>
                    <button v-if="edit" @click="editarDta()" :disabled="busy" class="btn-modal-success border-0 px-3 pb-2 pt-1">Actualizar</button>
                </b-overlay>
                <button @click="cerrarModal()" class="btn-modal-cancel px-3 pb-2 pt-1 ml-4">Cancelar</button>
            </div>
        </b-modal>
    </div> 
</template>

<script>
import { url } from "../../../../settings"

export default {
    name: 'Operaciones',
    props: ['modalOperaciones'],

    data: () => ({
        busy: false,
        busyEdit: false,
        save: true,
        edit: false,
        title: 'Ingresa la operación',
        msjError: '',
        type: '',
        id: '',
        dataOp: {
            nombre: ''
        }
    }),

    methods: {
        async guardar() {
            this.msjError = ''
            if (this.dataOp.nombre == '') {
                this.msjError = 'El nombre es obligatorio'
            } else if (this.dataOp.nombre.length < 5) {
                this.msjError = 'Operación invalida'
            } else {
                this.busy = true
                try {
                    const res = await fetch(`${url}/api/catalogos/operaciones/store`, {
                        method: 'post',
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer "+ localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.dataOp),
                    });
                    if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        this.busy = false
                        throw new Error(message);
                    }
                    const data = res.json()
                    console.log(data)
                    this.busy = false
                    this.dataOp.nombre = ''
                    this.$emit('savedOperation')
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async editarDta() {
            this.msjError = ''
            if (this.dataOp.nombre == '') {
                this.msjError = 'El nombre es obligatorio'
            } else if (this.dataOp.nombre.length < 5) {
                this.msjError = 'Operación invalida'
            } else {
                this.busy = true
                try {
                    const res = await fetch(`${url}/api/catalogos/operaciones/update/${this.id}`, {
                        method: 'PUT',
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "X-Requested-With": "XMLHttpRequest",
                            'Authorization': "Bearer "+ localStorage.getItem("token"),
                        },
                        body: JSON.stringify(this.dataOp),
                    })
                   if (!res.ok) {
                        const message = `An error has occured: ${res.status} - ${res.statusText}`;
                        this.busy = false
                        throw new Error(message);
                    }
                    const data = res.json()
                    console.log(data)
                    this.busy = false
                    this.dataOp.nombre = ''
                    this.id = ''
                    this.title = 'Ingresa la operación'
                    this.$emit('savedOperation')
                    this.save = true
                    this.edit = false
                } catch (error) {
                    console.log(error)
                }
            }
        },

        cerrarModal() {
            this.dataOp.nombre = ''
            this.save = true
            this.edit = false
            this.title = 'Ingresa la operación'
            this.$emit('closeAddOperaciones')
        },
        editDtaCat(id, name, type){
            this.title = 'Editar la operación'
            this.save = false
            this.edit = true

            this.id = id
            this.dataOp.nombre = name
            this.type = type
            console.log('Editar la operación');
        }
    },

    mounted() {
    }
}
</script>

<style scoped>

</style>